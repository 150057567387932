export default function About() {
	return <section id="about">
		<h1>About Us</h1>
		<div>
			<div className="pixel_desk">
				<div></div>
				<div></div>
			</div>
		</div>
		<p>We do high-quality, studio casting and streams for ESports tournaments and events with a little bit of flair.</p>
	</section>
}
