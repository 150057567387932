export default function Team() {
	return <section id="team">
		<h1>Our Team</h1>
		<div className="team">
			<div>
				<div><img src="/img/team/syncodd.jpg" alt="" /></div>
				<div>Syncodd</div>
				<div>Caster</div>
			</div>
			<div>
				<div><img src="/img/team/bloatcast.jpg" alt="" /></div>
				<div>Bloatcast</div>
				<div>Caster</div>
			</div>
		</div>
	</section>
}
